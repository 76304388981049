import {http} from '~/composables/useVzFetch';
import {AccountResponse} from '~/types/api';
import {EventRecord} from '~/types/components/personal/actions';

export function getEvents() {
  return $fetch<EventRecord[]>(
      `${http}/news`,
      { params: { week: true, sort: '-date', hidden: 0 } }
  )
}


export function getPromos() {
  return $fetch<AccountResponse>(
      `${http}/news`,
      { params: { sort: '-date', hidden: 0,  is_action: 1 } }
  )
}
