import {getEvents} from '~/api/news';
import {getNews} from '~/api/home';
import {EventRecord} from '~/types/components/personal/actions';

export const useNewsStore = defineStore('news', () => {
  const eventUnreadCount = ref({
    event: 0,
    notification: 0
  })
  const newsIds = ref([])
  const actionsIds = ref([])
  const lastEvents = ref([] as EventRecord[])
  const allNews = ref([] as EventRecord[])
  const allActions = ref([] as EventRecord[])

  // getters
  const lastFiveNews = computed(() => {
    return allNews.value.slice(0, 5)
  })
  const lastFiveActions = computed(() => {
    return allActions.value.slice(0, 5)
  })

  // actions
  const fetchNews = async (isAction = 0) => {
    try {
      const result = await getNews(1, { sort: '-date', is_action: isAction }) || [];
      if (isAction) {
        allActions.value = result;
        return;
      }

      allNews.value = result;
    } catch (error) {
      console.log(error)
    }
  }

  async function fetchEvents() {
    lastEvents.value = await getEvents()
  }

  return { eventUnreadCount, newsIds, actionsIds, lastEvents, lastFiveNews, lastFiveActions, fetchNews, fetchEvents }
})
